import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRoot } from "baobab-react/hooks";
import KendoCore from "kendo-ui-core/js/kendo.core";
import { merge } from "lodash-es";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import { KendoNotification } from "components/common/kendo/KendoNotification";
import customCultures from "config/custom-cultures";
import { queryClient } from "lib/query-client";
import tree from "services/AppStateSvc";

import { router } from "./app.router";

import "./app.scss";

export function App() {
  useEffect(() => {
    merge(KendoCore.cultures, customCultures);
    KendoCore.culture("international");
  }, []);

  const BaobabRoot = useRoot(tree);

  return (
    <BaobabRoot>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <div id="app">
          <RouterProvider router={router} future={{ v7_startTransition: true }} />
          <KendoNotification config={kendoNotificationConfig} />
        </div>
      </QueryClientProvider>
    </BaobabRoot>
  );
}

const kendoNotificationConfig = {
  button: true,
  position: {
    top: 60,
    right: 30,
  },
  width: 330,
};
