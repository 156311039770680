import { type Config, configRepo, type Environment } from "@rtbhouse-apps/commonlib";

export interface PanelConfig {
  version: string;
  environment: Environment;
  refreshInterval: number;

  apiHost: string;

  guiLoggerBaseUrl: string;
  guiLoggerDebugEnabled: boolean;
  guiLoggerDryRunEnabled: boolean;
  guiLoggerEnabled: boolean;

  sentryEnabled: boolean;
  sentryDsn: string;

  appSessionKey: string;
  recaptchaSiteKey: string;
}

export const config = configRepo.config as Config<PanelConfig>;

export async function fetchConfig() {
  return configRepo.fetchConfig<PanelConfig>();
}
