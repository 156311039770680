import cx from "classnames";
import { Link, type LinkProps } from "react-router-dom";

import "./NavLink.scss";

type NavLinkProps = LinkProps;

export function NavLink(props: NavLinkProps) {
  const { children, className, ...rest } = props;

  return (
    <Link className={cx("nav-link", className)} {...rest}>
      {children}
    </Link>
  );
}
