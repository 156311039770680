import tree from "../AppStateSvc";

const systemActions = {
  changePendingRequestsCount(delta) {
    const count = Math.max(tree.get(["http", "pendingRequestsCount"]) + delta, 0);

    tree.set(["http", "pendingRequestsCount"], count);

    if (count === 0) {
      tree.set(["loaders"], {});
    }
  },
};

export default systemActions;
