class RtbAppsError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ApiError extends RtbAppsError {}

export class ApiErrorForbidden extends ApiError {}

export class ApiErrorNotFound extends ApiError {}

export class ApiErrorTooManyRequests extends ApiError {}

export class AuthError extends RtbAppsError {}

export class UnexpectedError extends RtbAppsError {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchError<T, E extends new (...args: any[]) => Error>(
  promise: Promise<T>,
  errorsToCatch?: E[],
): Promise<[undefined, T] | [InstanceType<E>]> {
  return promise
    .then((data) => [undefined, data] as [undefined, T])
    .catch((error) => {
      if (errorsToCatch === undefined || errorsToCatch.some((e) => error instanceof e)) {
        return [error];
      }

      throw error;
    });
}
