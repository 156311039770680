import { Api, JsonApi, Method, TextApi } from "@rtbhouse-apps/rest-api-client";

import SystemActions from "services/actions/SystemActions";
import { config } from "services/config";

const genericApi = new Api(config);
const jsonApi = new JsonApi(genericApi);
const textApi = new TextApi(genericApi);

type ParamValue = number | string | string[] | null | undefined;
type Params = Record<string, ParamValue>;
type POJO = Record<string, unknown>;
type Payload = POJO | POJO[];

export const api = Object.freeze({
  buildUrl: genericApi.buildUrl.bind(genericApi),

  async healthCheck() {
    return textApi.get("healthcheck");
  },

  async request<T>(method: Method, path: string, params?: Params, payload?: Payload) {
    try {
      SystemActions.changePendingRequestsCount(1);
      const data = await jsonApi.request<Payload, T>(method, path, omitEmptyParams(params), serializePayload(payload));
      return parseData(data);
    } finally {
      SystemActions.changePendingRequestsCount(-1);
    }
  },

  async get<T>(path: string, queryParams?: Params) {
    return this.request<T>(Method.GET, path, queryParams);
  },

  async post<T>(path: string, payload?: Payload, queryParams?: Params) {
    return this.request<T>(Method.POST, path, queryParams, payload);
  },

  async put<T>(path: string, payload: Payload, queryParams?: Params) {
    return this.request<T>(Method.PUT, path, queryParams, payload);
  },

  async delete<T>(path: string, queryParams?: Params) {
    return this.request<T>(Method.DELETE, path, queryParams);
  },
});

function omitEmptyParams(params?: Params) {
  if (!params) {
    return params;
  }

  const entries = Object.entries(params).filter(function (entry): entry is [string, NonNullable<ParamValue>] {
    return entry[1] !== null && entry[1] !== undefined;
  });

  return Object.fromEntries(entries);
}

function parseData<T>(data: T) {
  return (Array.isArray(data) ? data.map(parseDateProps) : parseDateProps(data)) as T;
}

function parseDateProps<T>(data: T) {
  if (!isPOJO(data) || !hasDateFields(data)) {
    return data;
  }

  const result = {};

  for (const [key, value] of Object.entries(data)) {
    if (dateFields.includes(key) && typeof value === "string" && value !== "") {
      result[key] = new Date(value);
    } else if (typeof value === "object") {
      result[key] = parseDateProps(value);
    } else {
      result[key] = value;
    }
  }

  return result as T;
}

function serializePayload(payload?: Payload) {
  if (!payload) {
    return payload;
  }

  return Array.isArray(payload) ? payload.map(serializeDateProps) : serializeDateProps(payload);
}

function serializeDateProps(data: POJO) {
  const result = {} as POJO;

  for (const [key, value] of Object.entries(data)) {
    if (value instanceof Date) {
      result[key] = value.toISOString();
    } else if (isPOJO(value)) {
      result[key] = serializeDateProps(value);
    } else {
      result[key] = value;
    }
  }

  return result;
}

function isPOJO<T>(data: T): data is T & POJO {
  return typeof data === "object" && data !== null && !Array.isArray(data);
}

function hasDateFields<T extends object>(data: T) {
  return dateFields.some((field) => Object.hasOwn(data, field));
}

const dateFields = [
  "createdAt",
  "updatedAt",
  "deletedAt",
  "acceptedAt",
  "claimedAt",
  "invitationSentAt",
  "startDate",
  "endDate",
  "time",
];
