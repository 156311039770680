import { api } from "services/api/api";

import type {
  AuthStatusDto,
  ChangeUserPasswordPayloadDto,
  LogInDto,
  LogInPayloadDto,
  RequestPasswordResetPayloadDto,
  SetUserPasswordPayloadDto,
  VerifyMFATOTPPayloadDto,
} from "./auth.types";

export * from "./auth.types";

export async function fetchAuthStatus() {
  return api.get<AuthStatusDto>("auth/info");
}

export async function logIn(payload: LogInPayloadDto) {
  return api.post<LogInDto>("auth/login", payload);
}

export async function logOut() {
  return api.post("auth/logout");
}

export async function verifyMFATOTP(payload: VerifyMFATOTPPayloadDto) {
  return api.post("auth/login/verify-mfa/totp", payload);
}

export async function changeUserPassword(payload: ChangeUserPasswordPayloadDto) {
  return api.post("auth/change-password", payload);
}

export async function requestUserPasswordReset(payload: RequestPasswordResetPayloadDto) {
  return api.post("auth/reset-password", payload);
}

/**
 * @throws ErrorResponseException
 */
export async function setUserPassword(payload: SetUserPasswordPayloadDto) {
  return api.post("auth/new-password", payload);
}
