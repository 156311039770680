import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";

import { NavLink } from "components/NavLink/NavLink";
import { RTBLogo } from "components/RTBLogo/RTBLogo";

import "./layout.scss";

export function Layout() {
  useLayoutEffect(() => {
    const attrName = "data-theme";
    const htmlElement = document.documentElement;

    htmlElement.removeAttribute(attrName);

    return () => htmlElement.setAttribute(attrName, "legacy");
  }, []);

  return (
    <div className="auth-layout">
      <div className="auth-layout__main">
        <RTBLogo className="auth-layout__logo" />
        <div className="auth-layout__page-content">
          <Outlet />
        </div>
      </div>
      <div className="auth-layout__footer">
        <nav>
          <NavLink to="https://www.rtbhouse.com/" target="_blank">
            RTB House Corporate
          </NavLink>
          <NavLink to="https://www.rtbhouse.com/privacy/" target="_blank">
            Privacy Policy
          </NavLink>
          <NavLink to="https://www.rtbhouse.com/contact/" target="_blank">
            Contact
          </NavLink>
        </nav>
        <div className="copyright">Copyright © {new Date().getFullYear()} RTB House. All rights reserved.</div>
      </div>
    </div>
  );
}
