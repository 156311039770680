import KendoWindow from "components/common/kendo/KendoWindow";
import { ApiErrorForbidden, ApiErrorNotFound, ApiErrorTooManyRequests } from "lib/errors";

import { DashboardButton } from "./DashboardButton";

import "./ErrorWindow.scss";

interface FatalErrorWindowProps {
  error: Error;
}

export function FatalErrorWindow(props: FatalErrorWindowProps) {
  const { error } = props;
  const message = errorToMessage(error);

  return (
    <div id="fatal-error-window">
      <KendoWindow onWidgetReady={(kendoWindow) => kendoWindow.center().open()} config={kendoWindowConfig}>
        <h1>Whoops!</h1>
        <p>Looks like something went wrong.</p>

        <p>{message}</p>

        <DashboardButton error={error} />
      </KendoWindow>
    </div>
  );
}

const kendoWindowConfig = {
  appendTo: "#fatal-error-window",
  title: false,
  width: 900,
  height: 420,
  resizable: false,
  draggable: false,
};

function errorToMessage(error: Error) {
  if (error instanceof ApiErrorForbidden) {
    return "You don't have permission to perform this action.";
  }
  if (error instanceof ApiErrorNotFound) {
    return "Seems like you're looking for something that doesn't exist.";
  }
  if (error instanceof ApiErrorTooManyRequests) {
    return "Too many requests, please try again later.";
  }
  return "We're working on getting this fixed as soon as we can!";
}
