interface RTBLogoProps {
  className?: string;
}

export function RTBLogo(props: RTBLogoProps) {
  const { className } = props;

  return (
    <svg
      width="354"
      height="40"
      viewBox="0 0 354 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_828_1584)">
        <path d="M354 17.3843H322.687V21.6754H354V17.3843Z" fill="#EE4137" />
        <path d="M354 0.640152H322.687V4.93122H354V0.640152Z" fill="#EE4137" />
        <path
          d="M22.0682 39.2998L13.5527 24.8662H6.77636V39.2998H0V0.640152H16.9709C24.6168 0.640152 29.6541 5.57138 29.6541 12.7532C29.6541 19.935 25.1365 23.3558 20.439 24.116L29.9439 39.2998H22.0682ZM22.7078 12.6932C22.7078 8.98224 19.9293 6.61164 16.1014 6.61164H6.77636V18.9047H16.1014C19.9193 18.9047 22.7078 16.4741 22.7078 12.7032V12.6932Z"
          fill="#EE4137"
        />
        <path
          d="M43.9064 39.2998V6.61164H32.2027V0.640152H62.3865V6.61164H50.6828V39.3098H43.9064V39.2998Z"
          fill="#EE4137"
        />
        <path
          d="M66.9641 39.2998V0.640152H85.9639C93.09 0.640152 96.968 5.04125 96.968 10.4926C96.968 15.3038 93.8396 18.6046 90.2516 19.3648C94.4193 20.005 97.7275 24.116 97.7275 28.8172C97.7275 34.8487 93.7897 39.3098 86.4936 39.3098H66.9741L66.9641 39.2998ZM90.0217 11.5929C90.0217 8.75218 88.0527 6.61164 84.6346 6.61164H73.7404V16.5841H84.6346C88.0527 16.5841 90.0217 14.5536 90.0217 11.5929ZM90.7713 27.937C90.7713 25.0362 88.7424 22.5456 84.9244 22.5456H73.7404V33.3283H84.9244C88.5725 33.3283 90.7713 31.2978 90.7713 27.937Z"
          fill="#EE4137"
        />
        <path
          d="M137.986 39.2998V21.5654H115.398V39.2998H110.591V0.640152H115.398V17.2743H137.986V0.640152H142.793V39.2998H137.986Z"
          fill="white"
        />
        <path
          d="M149.11 20.005C149.11 8.58214 156.866 0 168.28 0C179.694 0 187.509 8.58214 187.509 20.005C187.509 31.4279 179.694 40 168.28 40C156.866 40 149.11 31.4179 149.11 20.005ZM182.532 20.005C182.532 10.9627 176.915 4.29107 168.28 4.29107C159.644 4.29107 154.087 10.9527 154.087 20.005C154.087 29.0573 159.594 35.7089 168.28 35.7089C176.965 35.7089 182.532 28.9872 182.532 20.005Z"
          fill="white"
        />
        <path
          d="M193.836 24.2861V0.640152H198.703V24.176C198.703 31.2478 202.471 35.7089 209.598 35.7089C216.724 35.7089 220.542 31.2478 220.542 24.176V0.640152H225.409V24.2361C225.409 33.8585 220.082 40.01 209.598 40.01C199.113 40.01 193.836 33.8084 193.836 24.2961V24.2861Z"
          fill="white"
        />
        <path
          d="M230.966 33.8485L233.864 30.1375C236.353 33.0383 240.521 35.6989 245.968 35.6989C252.864 35.6989 255.063 31.988 255.063 28.9772C255.063 19.0047 232.185 24.5661 232.185 10.7727C232.185 4.40109 237.862 -0.0100098 245.508 -0.0100098C251.355 -0.0100098 255.933 2.0205 259.181 5.44135L256.282 8.98224C253.384 5.73143 249.336 4.35108 245.108 4.35108C240.531 4.35108 237.173 6.8417 237.173 10.4926C237.173 19.1848 260.05 14.2035 260.05 28.6372C260.05 34.1985 256.232 40 245.798 40C239.142 40 234.154 37.4494 230.966 33.8585V33.8485Z"
          fill="white"
        />
        <path
          d="M266.067 39.2998V0.640152H291.384V4.93122H270.885V17.3943H291.384V21.6854H270.885V35.0187H291.384V39.3098H266.067V39.2998Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_828_1584">
          <rect width="354" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
