import { logger } from "@rtbhouse-apps/logger-react";

import { logIn, logOut } from "services/api/auth";

import tree from "../AppStateSvc";

const authActions = {
  loginUser(login, password) {
    return logIn({ login, password }).then((userInfo) => {
      tree.set(["user", "info"], userInfo);
      const { login, email } = userInfo;
      logger.setUser({
        login,
        email,
      });
      return userInfo;
    });
  },

  logoutUser() {
    return logOut().then(() => {
      tree.set(["user", "info"], {});
      tree.set(["advertisers"], null);
      logger.clearUser();
    });
  },
};

export default authActions;
