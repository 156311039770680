import { lazy } from "react";
import { createHashRouter, redirect } from "react-router-dom";

import AppErrorBoundary from "components/app/AppErrorBoundary";
import { Layout as AuthLayout } from "pages/auth/layout";
import authActions from "services/actions/AuthActions";

const LoginPage = lazy(() => import("pages/auth/login"));
const MFATotp = lazy(() => import("pages/auth/mfa-totp"));
const ForgotPasswordPage = lazy(() => import("pages/auth/forgot-password"));
const SetNewPasswordPage = lazy(() => import("pages/auth/set-new-password"));
const PasswordChangeRequiredPage = lazy(() => import("pages/auth/password-change-required"));

const ProtectedApp = lazy(() => import("components/app/ProtectedApp"));
const CreativesPreviewApp = lazy(() => import("components/app/CreativesPreviewApp"));

export const router = createHashRouter([
  {
    children: [
      {
        path: "/",
        loader: () => redirect("/dashboard"),
      },
      {
        path: "/auth",
        element: (
          <AppErrorBoundary>
            <AuthLayout />
          </AppErrorBoundary>
        ),
        children: [
          { path: "login", element: <LoginPage /> },
          { path: "mfa/totp", element: <MFATotp /> },
          { path: "forgot-password", element: <ForgotPasswordPage /> },
          { path: "new-password", element: <SetNewPasswordPage /> },
          { path: "password-change-required", element: <PasswordChangeRequiredPage /> },
          {
            path: "logout",
            loader: async () => {
              await authActions.logoutUser();
              return redirect("/auth/login");
            },
          },
        ],
      },
      {
        path: "/preview/*",
        element: (
          <AppErrorBoundary>
            <CreativesPreviewApp />
          </AppErrorBoundary>
        ),
      },
      {
        path: "*",
        element: (
          <AppErrorBoundary>
            <ProtectedApp />
          </AppErrorBoundary>
        ),
      },
    ],
  },
]);
