import $ from "jquery";
import { isEqual } from "lodash-es";
import { Component, type PropsWithChildren } from "react";
import { findDOMNode } from "react-dom";

import "kendo-ui-core/js/kendo.window";

type KendoWindowProps = {
  config?: Partial<kendo.ui.WindowOptions>;
  className?: string;
  onWidgetReady?: (widget: kendo.ui.Window) => void;
};

/**
 * IMPORTANT!
 * You should probably always use 'appendTo' option to prevent window from moving anywhere
 * (just move it to the same place), otherwise React "Invariant Violation" may appear
 * (react doesn't expect content to move or disappear)
 */
class KendoWindow extends Component<PropsWithChildren<KendoWindowProps>> {
  el?: JQuery<HTMLDivElement> = undefined;
  widget?: kendo.ui.Window = undefined;

  componentDidMount() {
    const { config, className, onWidgetReady } = this.props;

    this.el = $(findDOMNode(this) as HTMLDivElement);
    this.el?.kendoWindow({ modal: true, visible: false, width: "600px", ...config });
    this.widget = this.el?.data("kendoWindow");
    if (className) this.widget?.wrapper.addClass(className);
    if (onWidgetReady && this.widget) onWidgetReady(this.widget);
  }

  componentDidUpdate(prevProps: KendoWindowProps) {
    const { config } = this.props;
    if (!isEqual(config, prevProps.config)) {
      this.widget?.setOptions(config);
    }
  }

  componentWillUnmount() {
    this.widget?.destroy();
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default KendoWindow;
