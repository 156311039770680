import { decorateWithEventDispatcher } from "@rtbhouse-apps/reactlib/dist/decorateWithEventDispatcher";
import $ from "jquery";
import KendoCore from "kendo-ui-core/js/kendo.core";
import { type ComponentType, useCallback, useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";

import { sseEvents } from "lib/consts";

import "kendo-ui-core/js/kendo.notification";

interface EventDispatcherProps {
  addListener: (eventName: string, listener: EventListener) => void;
}

interface KendoNotificationProps {
  config: Record<string, unknown>;
}

function KendoNotificationComponent(props: KendoNotificationProps & EventDispatcherProps) {
  const { addListener, config } = props;
  const componentNodeRef = useRef<HTMLDivElement>(null);
  const el = useRef<{ kendoNotification: CallableFunction; data: CallableFunction }>();
  const widget = useRef<{ show: CallableFunction }>();

  const handleShowNotification = useCallback((e: Event) => {
    widget.current?.show(...(e as CustomEvent).detail);
  }, []);

  useEffect(() => {
    el.current = $(findDOMNode(componentNodeRef.current) as HTMLDivElement);
    el.current?.kendoNotification({ ...defaultConfig, ...config });
    widget.current = el.current?.data("kendoNotification");

    addListener(sseEvents.SHOW_NOTIFICATION, handleShowNotification);
    const element = el.current;

    return () => {
      KendoCore.destroy(element);
    };
  }, [addListener, config, handleShowNotification]);

  return <div ref={componentNodeRef} />;
}

export const KendoNotification = decorateWithEventDispatcher(
  KendoNotificationComponent,
) as unknown as ComponentType<KendoNotificationProps>;

const defaultConfig = {
  position: {
    top: 10,
    right: 10,
  },
  stacking: "down",
  width: 300,
};
